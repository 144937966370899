import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Twilio",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/twilio.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Twilio SMS`}</h1>
    <p>{`Receive and reply to Twilio text messages`}</p>
    <hr></hr>
    <p>{`This plugin lets you connect an SMS phone number to Crisp. You can now provide an SMS phone number to your customers and manage all your SMS queries with a Team Inbox and reply as a team. It also lets users to be notified on their phone`}</p>
    <p>{`Benefits of using Crisp and Twilio:`}</p>
    <ul>
      <li parentName="ul">{`Avoid distraction by keeping all your SMS in Crisp`}</li>
      <li parentName="ul">{`Be more productive with the Crisp Inbox features (Knowledge Base, Shortcuts, Assignments)`}</li>
      <li parentName="ul">{`Send files and images. (MMS is only compatible in the US and Canada)`}</li>
      <li parentName="ul">{`Notify users on their phone when they are not available on their desktop`}</li>
      <li parentName="ul">{`Build an SMS bot in a few minutes`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      